import {useBackgroundStyleConfigs} from 'hooks/useBackgroundStyleConfigs';
import React, {FC} from 'react';

import {TypoComponentsContentSectionBasic} from 'lib/api/strapi';

import {palette} from 'styles/mui/scss';

import {Box, Typography} from 'components/basic-components';
import {ButtonWithConfig} from 'components/basic-components/Button/ButtonWithConfig';
import MarkdownContent from 'components/basic-components/MarkdownContent';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import styles from './ContentSectionBasic.module.scss';

const ContentSectionBasic: FC<TypoComponentsContentSectionBasic> = ({
  header,
  headerLevel,
  content,
  button,
  showBanner,
  bannerText,
}) => {
  const {textColor, bannerColor} = useBackgroundStyleConfigs();
  return (
    <Box className={styles.wrapper} sx={{color: textColor}}>
      <Box className={styles.titleWrapper}>
        {header ? (
          <Typography
            component={headerLevel}
            variant="h3"
            className={styles.title}
            sx={{
              typography: {xxs: 'titleMobile', md: 'h1'},
            }}
          >
            {header}
          </Typography>
        ) : null}
        {showBanner && bannerText ? (
          <Box
            sx={{
              padding: '13px 20px',
              backgroundColor: bannerColor,
              width: 'fit-content',
              height: 'fit-content',
            }}
          >
            <MarkdownContent
              sx={{
                '& p': {
                  color:
                    bannerColor !== palette.colorPrimary
                      ? palette.colorPrimary
                      : palette.colorBlack,
                },
              }}
              className={styles.bannerText}
              content={bannerText}
            />
          </Box>
        ) : null}
      </Box>
      <MarkdownContent className={styles.markdown} content={content} />
      <ButtonWithConfig buttonProps={button} useThemeStyle />
    </Box>
  );
};
export default withErrorBoundary(ContentSectionBasic, ' ContentSectionBasic');
